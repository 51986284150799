export const createProtocolo = (token, seq) => {
  let ansCode;

  switch (token) {
    case "DC583":
      ansCode = "330809";
      break;
    case "W4F5G":
      ansCode = "416070";
      break;
    case "IN74CP":
      ansCode = "329665";
      break;
    case "A5GH8":
      ansCode = "422029";
      break;
    case "O44E8":
    default:
      ansCode = "";
  }

  const now = new Date();

  let day;
  let month;

  if (now.getDate() < 9) {
    day = `0${now.getDate()}`;
  } else {
    day = now.getDate();
  }

  if (now.getMonth() < 9) {
    month = `0${now.getMonth() + 1}`;
  } else {
    month = now.getMonth() + 1;
  }

  const protocolo =
    ansCode +
    now.getFullYear() +
    "" +
    month +
    "" +
    +day +
    "" +
    Math.floor(Math.random() * 10) +
    "" +
    +Math.floor(Math.random() * 10) +
    "" +
    Math.floor(Math.random() * 10) +
    +"" +
    Math.floor(Math.random() * 10);

  return protocolo;
};
