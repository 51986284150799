import React from "react";
import { Col, Row, FormInput, FormCheckbox } from "shards-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
    toolbar: ["bold", "italic", "underline", "strike"]
}

const formats = [
    "bold", "italic", "underline", "strike"
]

export default function typingBox(temContato, setDestinatario, checkEmail,
    setCheckEmail, checkSms, setCheckSms, handleCheckEmail, handleCheckSms,
    setPlaceholder, placeholder, checkWhatsapp, setCheckWhatsapp, handleCheckWhatsapp,
    setTemContato, checado, setChecado, handleChange, setMensagem, mensagem, pattern, maxLength, destinatario, token) {
    if (checado === true) {
        return (
            <div>
                <FormInput style={{
                    left: "109px", top: "214px", width: "387px", height: "18px",
                    fontFamily: "Open Sans, sans-serif", fontSize: "12px",
                    // borderColor: "rgba(62, 62, 62, 0.38)", borderWidth: "1px", borderRadius: "5px",
                }} type="text" maxLength={maxLength} pattern={pattern} value={destinatario}
                    className="teste" placeholder={placeholder}
                    onChange={e => setDestinatario(e.target.value)}
                /> <p>
                    <FormCheckbox id="email"
                        onChange={(e) => handleCheckEmail(setCheckEmail, setCheckSms,
                            setPlaceholder, setCheckWhatsapp, setChecado)}
                        checked={checkEmail}
                        style={{
                            left: "0px", borderColor: "rgba(62, 62, 62, 0.38)",
                            borderWidth: "1px",
                        }} />
                    <label for="email" className="label-checkbox">E-mail</label>
                    <FormCheckbox id="whatsapp"
                        onChange={(e) => handleCheckWhatsapp(setCheckWhatsapp,
                            setCheckEmail, setCheckSms, setPlaceholder, setChecado)}
                        checked={checkWhatsapp} />
                    <label for="whatsapp" className="label-checkbox">Whatsapp</label>
                    {/* <FormCheckbox id="sms" checked={checkSms}
                    onChange={(e) => handleCheckSms(setCheckSms, setCheckEmail, setPlaceholder,
                        setCheckWhatsapp, setChecado)} />
                <label for="sms" className="label-checkbox sms">SMS</label> */}
                    <FormCheckbox checked={checado} id="sem-contato"
                        onChange={(e) =>
                            handleChange(temContato, setTemContato,
                                checado, setChecado, setPlaceholder)} />
                    <label for="sem-contato" class="label-checkbox">Nenhum</label>
                </p>
            </div>
        );
    } else {
        if (checkEmail) {
            return (
                <div>
                    <div className="wrap">
                        <FormInput style={{
                            left: "109px", top: "214px", width: "387px", height: "18px",
                            fontFamily: "Open Sans, sans-serif", fontSize: "12px",
                            // borderColor: "rgba(62, 62, 62, 0.38)", borderWidth: "1px", borderRadius: "5px",
                        }} type="text" maxLength={maxLength} pattern={pattern}
                            className="teste" placeholder={placeholder} value={destinatario}
                            onChange={e => setDestinatario(e.target.value)}
                        />
                        <p>
                            <FormCheckbox id="email"
                                onChange={(e) => handleCheckEmail(setCheckEmail, setCheckSms,
                                    setPlaceholder, setCheckWhatsapp, setChecado)}
                                checked={checkEmail}
                                style={{
                                    left: "0px", borderColor: "rgba(62, 62, 62, 0.38)",
                                    borderWidth: "1px",
                                }} />
                            <label for="email" className="label-checkbox">E-mail</label>
                            <FormCheckbox id="whatsapp"
                                onChange={(e) => handleCheckWhatsapp(setCheckWhatsapp,
                                    setCheckEmail, setCheckSms, setPlaceholder, setChecado)}
                                checked={checkWhatsapp} />
                            <label for="whatsapp" className="label-checkbox">Whatsapp</label>
                            {/* <FormCheckbox id="sms" checked={checkSms}
                                    onChange={(e) => handleCheckSmssetCheckSms, setCheckEmail, setPlaceholder,
                                        setCheckWhatsapp, setChecado)} />
                                <label for="sms" className="label-checkbox sms">SMS</label> */}
                            <FormCheckbox checked={checado} id="sem-contato"
                                onChange={(e) =>
                                    handleChange(temContato, setTemContato,
                                        checado, setChecado, setPlaceholder)} />
                            <label for="sem-contato" class="label-checkbox">Nenhum</label>
                        </p>
                        <ReactQuill value={mensagem} onChange={(e) => setMensagem(e)}
                            placeholder="Digite uma mensagem..."
                            modules={modules} formats={formats} className="mail-box"
                        />
                        <br />
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="wrap">
                        <FormInput style={{
                            left: "109px", top: "214px", width: "387px", height: "18px",
                            fontFamily: "Open Sans, sans-serif", fontSize: "12px",
                        }} type="text" maxLength={maxLength} pattern={pattern}
                            className="teste" placeholder={placeholder} value={destinatario}
                            onChange={e => setDestinatario(e.target.value)}
                        />
                        <p>
                            <FormCheckbox id="email"
                                onChange={(e) => handleCheckEmail(setCheckEmail, setCheckSms,
                                    setPlaceholder, setCheckWhatsapp, setChecado)}
                                checked={checkEmail}
                                style={{
                                    left: "0px", borderColor: "rgba(62, 62, 62, 0.38)",
                                    borderWidth: "1px",
                                }} />
                            <label for="email" className="label-checkbox">E-mail</label>
                            <FormCheckbox id="whatsapp"
                                onChange={(e) => handleCheckWhatsapp(setCheckWhatsapp,
                                    setCheckEmail, setCheckSms, setPlaceholder, setChecado)}
                                checked={checkWhatsapp} />
                            <label for="whatsapp" className="label-checkbox">Whatsapp</label>
                            {/* <FormCheckbox id="sms" checked={checkSms}
                                    onChange={(e) => handleCheckSmssetCheckSms, setCheckEmail, setPlaceholder,
                                        setCheckWhatsapp, setChecado)} />
                                <label for="sms" className="label-checkbox sms">SMS</label> */}
                            <FormCheckbox checked={checado} id="sem-contato"
                                onChange={(e) =>
                                    handleChange(temContato, setTemContato,
                                        checado, setChecado, setPlaceholder)} />
                            <label for="sem-contato" class="label-checkbox">Nenhum</label>
                        </p>
                        <textarea name="textarea" rows="5"
                            className="mensagem-usuario" placeholder="Digite uma mensagem..."
                            value={mensagem} onChange={(e) => setMensagem(e.target.value)}
                            style={{
                                borderColor: "rgba(62, 62, 62, 0.38)", borderWidth: "2px"
                            }}
                        />
                    </div>
                </div>
            );
        }
    }
}