import React from "react";
import "../styles/global.js";

function Footer() {
    return (
        <footer>
        <p>Desenvolvido por DixHealth</p>
        <p>V0.001</p>
       </footer> 
    )
};

export default Footer;