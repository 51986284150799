import axios from "axios";
import Swal from "sweetalert2";
const routes = require("../config/routes.js");
var mensagemSucesso = "";
const telFilter = new RegExp(/[0-9]{11}/m);
const emailFilter = new RegExp(/@\w+\.\w{2,3}(\.\w{2,3})?/i);
var instancia = "DIX-03";

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        if (file) {
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        }
    });
};

export const handleSubmit = async (destinatario, protocolo, salvaTelefone, checkSms,
    checkEmail, checkWhatsapp, mensagem, arquivo, setArquivo, setPlaceholderAnexo,
    setMensagem, setDestinatario) => {
    const testResultEmail = emailFilter.test(destinatario);
    if (destinatario === "" && checkEmail) {
        Swal.fire({
            icon: "error",
            text: "Campo de e-mail deve ser preenchido.",
            timer: 5000,
            width: "539px",
            showConfirmButton: true
        })
    } else if (destinatario === "" && checkSms) {
        Swal.fire({
            icon: "error",
            text: "Campo de SMS deve ser preenchido",
            timer: 5000,
            width: "539px",
            showConfirmButton: true
        })
    } else if (destinatario === "" && checkWhatsapp) {
        Swal.fire({
            icon: "error",
            text: "Campo de Whatsapp deve ser preenchido.",
            timer: 5000,
            width: "539px",
            showConfirmButton: true
        })
    } else if (destinatario === "" && salvaTelefone) {
        Swal.fire({
            icon: "error",
            text: "Campo de telefone deve ser preenchido.",
            timer: 5000,
            width: "539px",
            showConfirmButton: true
        })
    }
    else {
        if (salvaTelefone) {
            mensagemSucesso = "Chamada salva com sucesso!";
        } else if (!salvaTelefone && checkEmail
            && !checkSms && !checkWhatsapp) {
            mensagemSucesso = "Email enviado com sucesso!";
        } else if (!salvaTelefone && !checkEmail
            && checkSms && !checkWhatsapp) {
            mensagemSucesso = "SMS enviado com sucesso!";
        } else if (!salvaTelefone && !checkEmail
            && !checkSms && checkWhatsapp) {
            mensagemSucesso = "Mensagem de Whatsapp enviada com sucesso!";
        }

        if (salvaTelefone) {
            if (telFilter.test(destinatario) === false) {
                Swal.fire({
                    icon: "error",
                    html: "Chamada n&atilde;o salva! Verifique o destinat&aacute;rio e sua conex&atilde;o.",
                    timer: 5000,
                    width: "400px",
                    showConfirmButton: true
                })
            } else {
                try {
                    await axios.post(routes.routes.dashboard_backend + "/v1/saveData", {
                        destinatario: destinatario, protocolo: protocolo, message: mensagem
                    }).then(async (res) => {
                        setMensagem("");
                        setDestinatario("");
                        Swal.fire({
                            icon: "success",
                            text: mensagemSucesso,
                            timer: 5000,
                            width: "400px",
                            showConfirmButton: true
                        });
                    });
                } catch (err) {
                    Swal.fire({
                        icon: "error",
                        html: "Email n&atilde;o enviado! Verifique o destinat&aacute;rio e sua conex&atilde;o.",
                        timer: 5000,
                        width: "400px",
                        showConfirmButton: true
                    })
                }
            }
        } else if (destinatario && checkEmail) {
            if (testResultEmail) {
                if (arquivo) {
                    try {
                        let anexo = [];
                        for (let i = 0; i < arquivo.length; i++) {
                            const base64 = await convertBase64(arquivo[i]);
                            let type = arquivo[i].type;
                            let key = arquivo[i].name;
                            anexo.push({ data: base64, type: type, key: key })
                        }
                        await axios.post(routes.routes.dashboard_backend + "/v1/upload", {
                            anexo: anexo, protocolo
                        }).then((res) => {
                            axios.post(routes.routes.dashboard_backend + "/v1/send/mail", {
                                destinatario: destinatario, protocolo: protocolo, mensagem: mensagem,
                                anexo: res.data
                            }).finally(async () => {
                                setArquivo("");
                                setPlaceholderAnexo("");
                                setMensagem("");
                                setDestinatario("");
                                Swal.fire({
                                    icon: "success",
                                    text: mensagemSucesso,
                                    timer: 5000,
                                    width: "400px",
                                    showConfirmButton: true
                                });
                                await axios.post(routes.routes.dashboard_backend + "/v1/saveData", {
                                    destinatario: destinatario, protocolo: protocolo, message: mensagem
                                })
                            })});
                    } catch (err) {
                        Swal.fire({
                            icon: "error",
                            html: "Email n&atilde;o enviado! Verifique o destinat&aacute;rio e sua conex&atilde;o.",
                            timer: 5000,
                            width: "400px",
                            showConfirmButton: true
                        })
                    }
                } else {
                    try {
                        await axios.post(routes.routes.dashboard_backend + "/v1/send/mail", {
                            destinatario: destinatario, protocolo: protocolo, mensagem: mensagem,
                        }).then(async (res) => {
                            setMensagem("");
                            setDestinatario("");
                            Swal.fire({
                                icon: "success",
                                text: mensagemSucesso,
                                timer: 5000,
                                width: "400px",
                                showConfirmButton: true
                            });
                            await axios.post(routes.routes.dashboard_backend + "/v1/saveData", {
                                destinatario: destinatario, protocolo: protocolo, message: mensagem
                            })
                        });
                    } catch (err) {
                        Swal.fire({
                            icon: "error",
                            html: "Email n&atilde;o enviado! Verifique o destinat&aacute;rio e sua conex&atilde;o.",
                            timer: 5000,
                            width: "400px",
                            showConfirmButton: true
                        })
                    }
                }
            } else {
                Swal.fire({
                    icon: "error",
                    html: "Email n&atilde;o enviado! Verifique o destinat&aacute;rio e sua conex&atilde;o.",
                    timer: 5000,
                    width: "400px",
                    showConfirmButton: true
                })
            }
        } else if (destinatario && checkSms) {
            if (telFilter.test(destinatario) === false) {
                Swal.fire({
                    icon: "error",
                    html: "SMS n&atilde;o enviado! Verifique o destinat&aacute;rio e sua conex&atilde;o.",
                    timer: 5000,
                    width: "400px",
                    showConfirmButton: true
                })
            } else {
                try {
                    await axios.post(routes.routes.dashboard_backend + "/v1/send/sms", {
                        destinatario: destinatario, protocolo: protocolo, mensagem: mensagem
                    }).then(async (res) => {
                        setMensagem("");
                        setDestinatario("");
                        Swal.fire({
                            icon: "success",
                            text: mensagemSucesso,
                            timer: 5000,
                            width: "400px",
                            showConfirmButton: true
                        });
                        await axios.post(routes.routes.dashboard_backend + "/v1/saveData", {
                            destinatario: destinatario, protocolo: protocolo, message: mensagem
                        })
                    });
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        html: "SMS n&atilde;o enviado! Verifique o destinat&aacute;rio e sua conex&atilde;o.",
                        timer: 5000,
                        width: "400px",
                        showConfirmButton: true
                    })
                }
            }
        } else if (destinatario && checkWhatsapp) {
            if (telFilter.test(destinatario) === false) {
                Swal.fire({
                    icon: "error",
                    html: "Mensagem de Whatsapp n&atilde;o enviada! Verifique o destinat&aacute;rio e sua conex&atilde;o.",
                    timer: 5000,
                    width: "400px",
                    showConfirmButton: true
                })
            } else {
                if (arquivo) {
                    try {
                        let anexo = [];
                        for (let i = 0; i < arquivo.length; i++) {
                            const base64 = await convertBase64(arquivo[i]);
                            let type = arquivo[i].type;
                            let key = arquivo[i].name;
                            anexo.push({ data: base64, type: type, key: key })
                        }
                        await axios.post(routes.routes.dashboard_backend + "/v1/upload", {
                            anexo: anexo, protocolo
                        }).then((res) => axios.post(routes.routes.dashboard_backend + "/v1/send/wpp",
                            {
                                destinatario: destinatario, protocolo: protocolo,
                                mensagem: mensagem, anexo: res.data, 
                                instancia: "DIX-03"
                            }).finally(async () => {
                                instancia = "";
                                setArquivo("");
                                setPlaceholderAnexo("");
                                setMensagem("");
                                setDestinatario("");
                                Swal.fire({
                                    icon: "success",
                                    text: mensagemSucesso,
                                    timer: 5000,
                                    width: "400px",
                                    showConfirmButton: true
                                });
                                await axios.post(routes.routes.dashboard_backend + "/v1/saveData", {
                                    destinatario: destinatario, protocolo: protocolo, message: mensagem
                                })
                            }))
                    } catch (error) {
                        Swal.fire({
                            icon: "error",
                            html: "Mensagem de Whatsapp n&atilde;o enviada! Verifique o destinat&aacute;rio e sua conex&atilde;o.",
                            timer: 5000,
                            width: "400px",
                            showConfirmButton: true
                        })
                    }
                } else {
                    try {
                        await axios.post(routes.routes.dashboard_backend + "/v1/send/wpp", {
                            destinatario: destinatario, protocolo: protocolo, 
                            mensagem: mensagem, instancia: "DIX-03"
                        }).then(async (res) => {
                            instancia = "";
                            setMensagem("");
                            setDestinatario("");
                            Swal.fire({
                                icon: "success",
                                text: mensagemSucesso,
                                timer: 5000,
                                width: "400px",
                                showConfirmButton: true
                            });
                            await axios.post(routes.routes.dashboard_backend + "/v1/saveData", {
                                destinatario: destinatario, protocolo: protocolo, message: mensagem
                            })
                        })
                    } catch (error) {
                        Swal.fire({
                            icon: "error",
                            html: "Mensagem de Whatsapp n&atilde;o enviada! Verifique o destinat&aacute;rio e sua conex&atilde;o.",
                            timer: 5000,
                            width: "400px",
                            showConfirmButton: true
                        })
                    }
                }
            }
        }
    }
}
