import { injectGlobal } from "styled-components";
import "font-awesome/css/font-awesome.css";
import background from "../images/01.png"

injectGlobal`
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
}
body, html {
  @import url(https://fonts.googleapis.com/css?family=Open+Sans);
  background-image: url(${background});
  background-size: cover;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  height: 100%;
  width: 100%;
}

footer{
  position:absolute;
  bottom:20px;
  width:100%;
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 11px;
/* or 115% */
text-align: center;
color: #FFFFFF;
}
`;